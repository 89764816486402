import React, { useState } from "react";
import Layout from "../components/layout/Layout"
import Container from "../components/layout/Container"
import Type from "../components/typography/Type"
import { isLoggedIn } from "../services/auth"
import LoginPrompt from "../components/app/loginPrompt"


const Thanks = () => {
  

  
  

  
  return (
    <Layout>
    <Container>
    
    
    {isLoggedIn() ? (
      <div>
        
        <Type type="h4">Thanks</Type> 
      </div> 
   ) : (
       <div>
         <LoginPrompt/>
       </div>     
     )}



   </Container>
    </Layout>
  )
}

export default Thanks

export const Head = () => <title>Sessions</title>

